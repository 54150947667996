import { toast } from 'react-toastify';
import storeInstance from './store';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
	, debug = process.env.REACT_APP_DEBUG === 'true';

export const capitalise = (str) => {
	if (str && typeof str === 'string') {
		return str.charAt(0).toUpperCase() + str.substring(1);
	}
	return '';
};

export const propertyAddressToString = (row, index) => {
	return `${row.address.address_one || ''} ${row.address.address_two || ''}, ${row.address.suburb} ${row.address.postcode}, ${row.address.state}, ${row.address.country}`;
};

export function clearUserDetails() {
	localStorage.removeItem('bpm:user-details');
}

// Get user details
export function getUserDetails() {
	let userDetails = localStorage.getItem('bpm:user-details');
	if (userDetails) {
		return JSON.parse(userDetails);
	} else {
		return null;
	}
}

export const saveUserDetails = (res) => {
	localStorage.setItem('bpm:user-details', JSON.stringify(res));
	storeInstance.setUserDetails(res);
};

const callApi = async (path, method, body, useCredentials = false) => {
	let options = {
		method,
		headers: {
			'app-version': 'web',
		}
	};
	if (method.toLowerCase() !== 'get') {
		options.body = JSON.stringify(body);
	}
	if (useCredentials) {
		const userDetails = getUserDetails();
		if (!userDetails) {
			return new Error('Missing credentials');
		}
		const { user_id, token } = userDetails;
		options.headers = {
			...options.headers,
			'User-ID': user_id,
			'Authorization': token
		};
	}
	let res;
	try {
		res = await fetch(`${API_BASE_URL}${path}`, options).then(res => res.json());
	} catch (e) {
		debug && console.error(e);

		// check if its the file response blob thing instead 
		try{
			res = await fetch(`${API_BASE_URL}${path}`, options).then(res => res.blob());
			return res;
		} catch (e) {
			return new Error('An unexpected error occurred.');
		}
		// toast.error('An unexpected error occurred');
	}
	if (res.error) {
		// if (res.error.code === 40018 || res.error.name === 'invalid_user_id') {
		//	 toast.error('Your Session Expired, Please Sign In.', {toastId: customId});
		//	 history.push('/');
		//	 clearUserDetails();
		//	 return null;
		// }
		debug && console.error(res.error.desc);
		return new Error(res.error.desc);
	}
	return res;
};

export async function login(identifier, password) {
	const res = await callApi('/users/login', 'POST', {
		identifier,
		password,
	});
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	saveUserDetails(res);
	return res;
}

export async function logout() {
	const res = await callApi('/users/logout', 'DELETE', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	clearUserDetails();
	return res;
}

export async function validateToken() {
	const res = await callApi('/users/validate', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function requestPasswordReset(identifier) {
	const res = await callApi('/users/forgot-password', 'POST', {
		identifier,
	});
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function validateResetToken(token) {
	const res = await callApi('/users/reset-password/validate', 'POST', {
		token,
	});
	if (res instanceof Error) {
		return null;
	}
	return true;
}

export async function resetPassword(token, password, confirmPassword) {
	const res = await callApi('/users/reset-password', 'POST', {
		token,
		password,
		confirm_password: confirmPassword, //if python used camelCase the world would be a better place
	});
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getCurrentUserDetails() {
	const res = await callApi('/users/details', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	storeInstance.setUserDetails(res);
	return res;

}

/** 
 * The function below will be used to call a list of sites that the user has
 * access to. No thanks to mr 404, there weren't any comments.
 * So im commenting now
 */
export async function getSites() {
	const res = await callApi('/sites', 'GET', null, true);
	if(res instanceof Error) {
		toast.error(res.message);
		return null;
	}

	// should save the sites into the localStorage at userDetails for now
	let userDetails = getUserDetails();
	if (userDetails) {
		userDetails.sites = res; // array of sites
		//now save the user details after adding sites
		saveUserDetails(userDetails)
		storeInstance.setSites(res);
	}
	

	return res;
}

/** 
 * This function will be used to download the excel
 */
export async function downloadSubmittedReports(fromDate, toDate){
	const res = await callApi(`/forms/download?from_date=${fromDate}&to_date=${toDate}`, 'GET', null, true);
	if(res instanceof Error){
		toast.error(res.message);
		return null;
	}
	return res;
}

/** 
 * This function will be used to download an excel report for a form
 */
export async function downloadSingleReport(formId){
	const res = await callApi(`/submitted/${formId}/download`, 'GET', null, true);
	if(res instanceof Error){
		toast.error(res.message);
		return null;
	}
	return res;
}
export async function getProperties() {
	const res = await callApi('/properties', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function addProperty(propertyName, pic, tradingName,
								  addressOne, addressTwo, suburb, state, postcode, country) {
	const res = await callApi('/properties', 'POST', {
		property_name: propertyName,
		pic,
		trading_name: tradingName,
		address: {
			address_one: addressOne,
			address_two: addressTwo,
			suburb,
			state,
			postcode,
			country,
		}
	}, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getChemicals() {
	const res = await callApi('/chemicals', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}



export function flattenForm(form) {
	//per-form for use on SpecificForm for drafts
	return (form.sections).reduce((acc, section) => {
		const { section_id, section_name, section_description, section_details, position, user_type } = section;
		const flattenedSectionQuestions = form.questions.filter((e) => e.section_id === section_id).map(question => ({
			...question,
			section_position: position,
			section_id,
			section_name,
			section_description,
			section_details,
			user_type,
		}))
		acc = acc.concat(flattenedSectionQuestions);
		return acc;
	}, []);
}

export function flattenForms(forms) {
	//transforming it back into the old format, and we will transform it
	//back on submission, since the old single array format was way easier
	forms.forEach(form => {
		const allSectionsQuestions = flattenForm(form);
		form.questions = allSectionsQuestions;
	});
	return forms;
}

export async function getFormByFormId(formId) {
	const res = await callApi(`/form/${encodeURIComponent(formId)}`, 'GET', null, false);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	const allSectionsQuestions = flattenForm(res);
	res.questions = allSectionsQuestions;
	return res;
}

export async function getForms() {
	const res = await callApi('/forms', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	const forms = flattenForms(res);
	return forms;
}

const keyMaps = {
	'chemical_details': {
		chemical_name: 'Chemical Product',
		date_applied: 'Date Applied',
		withholding_period: 'WHP',
		esi: 'ESI (If Set)',
	},
	'grazing_chemical_details': {
		chemical_name: 'Chemical Product',
		date_applied: 'Date Applied',
		withholding_period: 'Grazing WHP',
		date_grazed: 'Date First Fed/Grazed',
		date_ceased: 'Date Feeding/Grazed Ceased',
	}
};

const arrayAnswerQuestionTypes = new Set([
	'chemical_details',
	'grazing_chemical_details',
//	'documents',
	'sheep_type',
	'cattle_type',
	'horse_type',
	'predefined_table_type',
	//any array type questions go here
]);

export function createAnswers(questions) {
	const answers = {}

	questions.forEach(q => {
		let answerValue = q.answer_hint !== null ? q.answer_hint : null;
		if (answerValue === null) {
			switch (q.data_type) {
				case 'text':
				case 'int':
					answerValue = '';
					break;
				case 'boolean':
					answerValue = null;
					break;
				default:
					if (arrayAnswerQuestionTypes.has(q.question_type)) {
						answerValue = [];
					} else if (q.data_type.constructor.name === 'Object'
						|| q.data_type === 'json') {
						answerValue = {};
					} else {
						//any more?
						answerValue = {};
					}
					break;
			}
		}
		answers[q.question_id] = {
			value: answerValue,
			attachments: [],
		}
	});

	storeInstance.setAnswers({});
	storeInstance.setAnswers(answers);
}

export function applyAnswers(answers) {
	let existingAnswers = storeInstance.answers;
	Object.entries(answers).forEach(a => {
		//apply answers that were passed in
		const [question_id, valueAndAttachments] = a;
		existingAnswers[question_id] = valueAndAttachments;
	});
	//and convert human readable to underscores again
	existingAnswers = reverseProcessAnswers(existingAnswers);
	storeInstance.setAnswers(existingAnswers);
}

//Human Readable Names - > snake_case_names
export function reverseProcessAnswers(answers) {
	let processedAnswers = answers;
	for (let answerKey in processedAnswers) {
		const answer = processedAnswers[answerKey];
		//process some question_types to have friendly names for draft API submissions
		const answerQuestion = storeInstance.questions.find(q => q.question_id === answerKey);
		if (answerQuestion) {
			const keyMap = keyMaps[answerQuestion.question_type];
			if (keyMap != null) {
				Object.entries(keyMap).forEach(keyMapEntry => {
					const [snakeCaseKey, humanReadableKey] = keyMapEntry;
					if (Array.isArray(answer.value)) {
						answer.value.forEach(arrayAnswerValue => {
							if (arrayAnswerValue[humanReadableKey] != null) {
								arrayAnswerValue[snakeCaseKey] = arrayAnswerValue[humanReadableKey];
								delete arrayAnswerValue[humanReadableKey];
							}
						})
					}
				});
			}
		}
	}
	return answers;
}

//snake_case_names -> Human Readable Names
export function processAnswers(filteredAnswers) {
	let processedAnswers = filteredAnswers;
	for (let answerKey in processedAnswers) {
		const answer = processedAnswers[answerKey];
		//process some question_types to have friendly names for draft API submissions
		const answerQuestion = storeInstance.questions.find(q => q.question_id === answerKey);
		switch (answerQuestion.question_type) {
			case 'cattle_type':
			case 'sheep_type':
			case 'horse_type':
			case 'chemical_details':
			case 'grazing_chemical_details':
				answer.value.forEach(arrayAnswerVal => {
					//for array answers...
					for (let key in arrayAnswerVal) {
						//for the keys in each array elem...
						const mappedKey = keyMaps[answerQuestion.question_type][key];
						if (mappedKey) {
							//if a key should be changed
							arrayAnswerVal[mappedKey] = arrayAnswerVal[key];
							//switch them and delete the old one
							delete arrayAnswerVal[key];
						}
					}
				});
				break;
			default:
				//not a question type that needs processing, for now
				break;
		}

	}
	return processedAnswers;
}

export async function getSubmittedForms() {
	const res = await callApi('/submitted', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getSubmittedForm(submittedFormId) {
	const res = await callApi(`/answers?submitted_form_id=${encodeURIComponent(submittedFormId)}`, 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getSavedDrafts() {
	const res = await callApi('/drafts', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getAnswers(formId) {
	const res = await callApi(`/answers?submitted_form_id=${formId}`, 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getSubUsers() {
	const res = await callApi('/account/users', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function deleteSubUsers(idArray) {
	const res = await callApi('/account/user', 'DELETE', {
		users: idArray || [],
	}, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}


export async function addSubUser(first_name, last_name, identifier, password, contact_number, role_name) {
	const res = await callApi('/account/user', 'POST', {
		identifier,
		password,
		first_name,
		last_name,
		contact_number,
		role_name,

	}, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function updateUser(body){
	const res = await callApi('/account/user/edit', 'POST', body, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function saveDraftForm(body){
	const res = await callApi('/draft', 'POST', body, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function signDriverDeclarationNOAUTH(body) {
	const res = await callApi('/driver-nocred', 'POST', body);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function signDriverDeclaration(body) {
	const res = await callApi('/driver-declaration', 'POST', body, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function submitForm(body){
	const res = await callApi('/submit', 'POST', body, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getContacts() {
	const res = await callApi('/account/contacts', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	const {
		contact_id,
		contact_name,
		company_name,
		contact_number,
		contact_email,
		address,
	} = res;
	const contactDetails = JSON.stringify({
		contact_id,
		contact_name,
		company_name,
		contact_number,
		contact_email,
		address,
	});
	localStorage.setItem('bpm:contact-details', contactDetails);
	return res;
}

export async function validateShareToken(token) {
	const res = await callApi(`/sms-form?token=${token}`, 'GET', null, false);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}
