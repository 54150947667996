import { action, decorate, observable } from "mobx";

class Store {
	userDetails = {};
	transports = [];
	questions = [];
	properties = [];
	livestock = [];
	users = [];
	sites = [];
	answers = {};
	chemicals = [];
	filteredData = [];
	savedDrafts = [];
	submittedForms = [];
	forms = [];
	contactDetails = [];
	formType = null;
	consigner = null;

	setFilteredData(filteredData) {
		this.filteredData = filteredData.map((x, i) => {
			x.id = i;
			return x;
		});
	}

	setAnswers(answers) {
		this.answers = answers;
	}

	setFormType(formType) {
		this.formType = formType;
	}

	setUserDetails(userDetails) {
		this.userDetails = userDetails;
	}

	getUserDetails(){
		return this.userDetails;
	}

	getQuestionIdByType(type) {
		const foundQuestion = this.questions.find(q => q.question_type === type);
		return foundQuestion ? foundQuestion.question_id : null;
	}

	setLivestock(livestock) {
		this.livestock = livestock;
	}

	setForms(forms) {
		this.forms = forms;
	}

	setChemicals(chemicals) {
		this.chemicals = chemicals;
	}

	setSubmittedForms(forms){
		this.submittedForms = forms;
	}

	setSavedDrafts(drafts){
		this.savedDrafts = drafts;
	}

	setTransports(transports) {
		this.transports = transports;
	}

	setProperties(properties) {
		this.properties = properties;
	}
	setSites(sites) {
		this.sites = sites;
	}

	setQuestions(newQuestions) {
		this.questions = newQuestions;
	}

	setUsers(newUsers) {
		this.users = newUsers;
	}

	setContactDetails(contactDetails) {
		this.contactDetails = contactDetails;
	}

	setConsigner(consigner) {
		this.consigner = consigner;
	}

}

decorate(Store, {
	formType: observable,
	savedDrafts: observable,
	userDetails: observable,
	transports: observable,
	properties: observable,
	sites: observable,
	livestock: observable,
	users: observable,
	questions: observable,
	answers: observable,
	chemicals: observable,
	forms: observable,
	contactDetails: observable,
	consigner: observable,
	setUserDetails: action,
	submittedForms: observable,
	setAnswers: action,
	setChemicals: action,
	setSavedDrafts: action,
	setTransports: action,
	setQuestions: action,
	setUsers: action,
	setForms: action,
	setSubmittedForms: action,
	setLivestock: action,
	setProperties: action,
	setContactDetails: action,
	setConsigner: action,
	setFormType: action,
});

const storeInstance = new Store();
export default storeInstance;
