import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, } from "reactstrap"

import * as Icon from "react-feather"
import { history } from "../../../history"
import { getUserDetails, logout } from "../../../api";
import storeInstance from "../../../store";
import { observer } from 'mobx-react';

const UserDropdown = props => {

	async function logOut() {
		const logoutSuccess = await logout();
		if (!logoutSuccess) {
			return null;
		}
		history.push("/");
	}

	return (
		<DropdownMenu right>
			<DropdownItem
				className='logoutOverride'
				tag="a"
				href="#"
				onClick={() => logOut()}
			>
				<Icon.Power size={14} className="mr-50"/>
				<span className="align-middle">Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	)

};

const NavbarUser = observer(class NavbarUser extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			navbarSearch: false,
			suggestions: [],
		}
	}

	async componentDidMount() {
		const userCredential = getUserDetails();
		if (!userCredential) {
			return history.push('/');
		}
	}

	render() {

		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
					<DropdownToggle tag="a" className="nav-link dropdown-user-link">
						<div className="user-nav d-sm-flex d-none">
							<span className="text-bold-600 pb-1">
								{storeInstance.userDetails && `${storeInstance.userDetails.first_name} ${storeInstance.userDetails.last_name}`}
							</span>
							{((storeInstance.userDetails) && (storeInstance.userDetails.role))
							&&
							<span className="user-status">
								{(storeInstance.userDetails.role) === 'admin' ? 'Admin'
									: storeInstance.userDetails.role === 'account_user' ? 'User' : 'Driver'}
							</span>}
						</div>
						<span data-tour="user">
							{/*{*/}
							{/*	let organisation = storeInstance.userDetails.organisation;*/}
							{/*}*/}
							{/*<img*/}
							{/*	src={storeInstance.userDetails.organisation.style.logo || this.props.photo}*/}
							{/*	className="round"*/}
							{/*	height="40"*/}
							{/*	width="40"*/}
							{/*	alt="avatar"*/}
							{/*/>*/}
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		)
	}

});

export default NavbarUser;
