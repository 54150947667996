import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import classnames from "classnames"
import logo from "../../../../assets/img/logo/refrmd.png"
import storeInstance from "../../../../store";
import { getUserDetails } from "../../../../api";
import { history } from "../../../../history";

class SidebarHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        const userCredentials = getUserDetails();
        if(!userCredentials){
            return history.push('/');
        }
        storeInstance.setUserDetails(userCredentials);
        this.setState({ visible: true })
    }


    render() {
        if (!this.state.visible) {
            return null;
        }
        let {
            menuShadow
        } = this.props;
        let org = storeInstance.userDetails ? storeInstance.userDetails.organisation : null;
        let image = (org && org.style.logo);
        return (
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto">
                        <NavLink to="/dashboard" className="navbar-brand">
                              <img className="brand-logo" src={image != null ? image : logo} alt={"ReFRMD"}/>
                            {/** /** THIS IS LOGO ReFRMD */ }
                        </NavLink>
                    </li>
                </ul>
                <div
                    className={classnames("shadow-bottom", {
                        "d-none": menuShadow === false
                    })}
                />
            </div>
        )
    }

}

export default SidebarHeader;
